import * as React from "react";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CarouselDefault } from "react-responsive-carousel";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Carousel(props) {
  const [t] = useTranslation("global");
  const { toView, listItem } = props;
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    let elementList = [];
    let itemCarousel = [];
    for (let i = 0; i < listItem.length; i++) {
      const item = listItem[i];
      if (i % 3 === 0 && i !== 0) {
        elementList.push({ list: itemCarousel });
        itemCarousel = [];
      }
      itemCarousel.push(item);
    }
    elementList.push({ list: itemCarousel });
    console.log(listItem, elementList);
    setList(elementList);
  }, [listItem]);
  return (
    <CarouselDefault
      showThumbs={false}
      showArrows={true}
      className="d-block-700"
    >
      {list.map((itemRow, indexRow) => (
        <Grid container spacing={2} key={indexRow} pb={4}>
          {itemRow.list.map((row, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <div className="boxShadowEvent">
                <div
                  className="homeImgItemEvent"
                  // onClick={() => viewContent(row.key)}
                  style={{
                    backgroundImage: `url(${row.img})`,
                  }}
                ></div>
                <Typography padding={"10px"} fontWeight={"bold"}>
                  {row.title}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      ))}
    </CarouselDefault>
  );
}
