import { Button } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";

export default function Account() {
  const navigate = useNavigate();

  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Sorry!</h1>
      <p>Page, in progress.</p>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/home")}
      >
        Go Home
      </Button>
    </div>
  );
}
