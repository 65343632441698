import * as React from "react";
import {
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLogin from "../../components/HeaderLogin";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/advlogo.png";
import Carousel from "./Carousel";
import SearchIcon from "@mui/icons-material/Search";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { isAuthenticated } from "../../apis/utils";
import MenuHeader from "../../components/MenuHeader";

export default function Index() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [tabActive, setTabActive] = React.useState(null); //9=about 1= agenda ....
  const [search, setSearch] = React.useState("");
  const [listItem, setListItem] = React.useState([]);
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));

  const handleChange = (event, newValue) => {
    setTabActive(newValue);
  };

  const viewContent = (key) => {
    // if (!isAuthenticated()) {
    //   return setModalConfig(
    //     modalMsgFunction(
    //       true,
    //       3,
    //       "Information",
    //       "You need to login to see the content"
    //     )
    //   );
    // }
    console.log(key);
    return navigate("/view/" + key);
  };

  const closeModal = (type) => {
    setModalConfig(
      modalMsgFunction(
        false,
        type,
        "Information",
        "You need to login to see the content"
      )
    );
  };

  const filterCategory = (valueSearch) => {
    setSearch(valueSearch);
  };

  React.useEffect(() => {
    console.log("change search");
    if (search == "") {
      setListItem(dataHome);
    } else {
      let filterData = dataHome.filter(
        (item) => item.title.toUpperCase().search(search.toUpperCase()) >= 0
      );
      console.log(filterData);
      setListItem(filterData);
    }
  }, [search]);

  const dataHome = [
    {
      title: "Adventist Education",
      type: "Education",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/conference1.png",
          key: 789,
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/conference2.png",
          key: "663258956",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/conference3.png",
          key: 123,
        },
        /*  {
           img: "https://www.campmeeting.com/wp-content/uploads/2022/08/Screen-Shot-2022-08-04-at-2.19.40-PM.png",
           key: 789,
         },
         {
           img: "https://www.campmeeting.com/wp-content/uploads/2021/09/Screen-Shot-2021-09-01-at-2.34.02-PM-1100x500.png",
           key: "663258956",
         },
         {
           img: "https://www.campmeeting.com/wp-content/uploads/2021/08/books-on-media-960x500.jpg",
           key: 123,
         }, */
      ],
    },
    {
      title: "Discipleship",
      type: "Discipleship",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.24.56.png",
          key: "6545612145645",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.25.54.png",
          key: "6545612145644",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.31.32.png",
          key: "3339",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.32.09.png",
          key: "2226",
        },
      ],
    },
    {
      title: "Faith & Science",
      type: "Faith",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.44.20.png",
          key: "985",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.53.26.png",
          key: "986",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.01.06.png",
          key: "987",
        },
      ],
    },
    {
      title: "Health & Wellness",
      type: "Health",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.12.25.png",
          key: "988",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.16.07.png",
          key: "1002",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.21.12.png",
          key: "1003",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.26.22.png",
          key: "1004",
        },
      ],
    },
    {
      title: "Mission",
      type: "Mission",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.30.03.png",
          key: "1005",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.32.55.png",
          key: "1006",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.36.35.png",
          key: "1007",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.39.38.png",
          key: "1008",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.43.29.png",
          key: "1009",
        },
      ],
    },
    {
      title: "Theology & Spirituality",
      type: "Theology",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.47.04.png",
          key: "1010",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.51.12.png",
          key: "1011",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.53.10.png",
          key: "1012",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.58.19.png",
          key: "1013",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.01.09.png",
          key: "1014",
        },
      ],
    },
    {
      title: "Culture, Unity, & Race",
      type: "Culture",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.16.47.png",
          key: "1015",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.19.45.png",
          key: "1016",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.22.50.png",
          key: "1017",
        },
      ],
    },
    {
      title: "Leadership & Management",
      type: "Leadership",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.24.33.png",
          key: "1018",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.27.00.png",
          key: "1019",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.30.51.png",
          key: "1020",
        },
      ],
    },
    {
      title: "Media Ministries",
      type: "Media",
      listContent: [
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.47.04.png",
          key: "1021",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.34.08.png",
          key: "1022",
        },
        {
          img: "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.36.16.png",
          key: "1023",
        },
      ],
    },
  ];

  return (
    <>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
      <div
        style={{
          minHeight: "55px",
          marginTop: "6px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          arginBottom: "23px",
          padding: "10px 15px",
          margin: "0px",
          background: "#fff",
        }}
      >
        <img src={logo} alt="logo" width="55" height="55" className="imgIcon" />
        <div>{isAuthenticated() ? <MenuHeader /> : <HeaderLogin />}</div>
      </div>
      <Carousel toView={viewContent} />
      <Grid container spacing={2} padding={1} mb={2}>
        <Grid item xs={12}>
          <div
            style={{
              marginTop: "18px",
              background: "#fff",
              borderRadius: "20px",
              padding: "10px",
              display: "flex",
            }}
          >
            <Tabs
              value={tabActive}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="All" value={null} style={{ color: "#000" }} />
              <Tab
                label="Adventist Education"
                value={"Education"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Discipleship"
                value={"Discipleship"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Faith & Science"
                value={"Faith"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Health & Wellness"
                value={"Health"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Mission"
                value={"Mission"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Theology & Spirituality"
                value={"Theology"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Culture, Unity, & Race"
                value={"Culture"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Leadership & Management"
                value={"Leadership"}
                style={{ color: "#000" }}
              />
              <Tab
                label="Media Ministries"
                value={"Media"}
                style={{ color: "#000" }}
              />

              {/* <Tab label="N5FW" value={4} style={{ color: "#000" }} />
              <Tab label="CATEGORIES" value={5} style={{ color: "#000" }} />
              <Tab label="USERS" value={6} style={{ color: "#000" }} />
              <Tab label="TREND" value={7} style={{ color: "#000" }} /> */}
              {/* <Button color="inherit">Dashboard</Button> */}
              {/* rgb(47, 85, 127) */}
            </Tabs>
            <TextField
              id="search"
              variant="outlined"
              name="search"
              size="small"
              value={search}
              onChange={(e) => filterCategory(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        minHeight={"500px"}
        alignContent={"flex-start"}
        padding={1}
      >
        {listItem.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            padding={1}
            style={{
              display:
                tabActive !== null && item.type != tabActive ? "none" : "block",
            }}
          >
            <div
              style={{
                padding: "10px",
                background: "#fff",
                borderRadius: "10px",
              }}
            >
              <Typography fontSize={23} mb={2} color={"black"}>
                {item.title}
              </Typography>
              <div
                style={{
                  overflowX: "scroll",
                  paddingBottom: "13px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {item.listContent.map((row, indexRow) => (
                    <div
                      key={indexRow}
                      className="homeImgItem"
                      onClick={() => viewContent(row.key)}
                      style={{
                        border: "1px solid black",
                        backgroundImage: `url(${row.img})`,
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
