import "./assets/css/globals.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import global_example from "./assets/translations/example/global.json";
import global_en from "./assets/translations/en/global.json";
import global_es from "./assets/translations/es/global.json";

import i18next from "i18next";
import ErrorPage from "./pages/error/error-page";
import Login from "./pages/login";
import Recover from "./pages/login/recover";
import CreateAccount from "./pages/login/createAccount";
import Home from "./pages/home";
import Events from "./pages/events";
import View from "./pages/view/index";
import { apiGetSession } from "./apis";

import React from "react";
import { nextService } from "./apis/Services/Authentication";
import Account from "./pages/Account/Account";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    example: {
      global: global_example,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: { main: "#2f557f", contrastText: "#fff" },
    secondary: { main: "#6f6f6f", contrastText: "#000" },
    text: { secondary: "#f0f0f0" },
  },
});

function App() {
  React.useEffect(() => {
    apiGetSession().then((result) => {
      console.log(result.init);
      localStorage.setItem("session", result.init);
      nextService({ data: result.init }).then((r) => {
        console.log(r.access_token);
        localStorage.setItem("out", r.access_token);
      });
    });
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<ErrorPage />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="account" element={<Account />} />
        <Route path="recover" element={<Recover />} />
        <Route path="createAccount" element={<CreateAccount />} />
        <Route path="home" element={<Home />} />
        <Route path="events" element={<Events />} />
        <Route path="view/:id" element={<View />} />
      </Route>
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
