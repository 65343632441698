import * as React from "react";
import Typography from "@mui/material/Typography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CarouselDefault } from "react-responsive-carousel";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CarouselMobile(props) {
  const [t] = useTranslation("global");
  const { listItem } = props;
  const [list, setList] = React.useState([]);

  return (
    <CarouselDefault
      showThumbs={false}
      showArrows={true}
      className="d-none-700"
    >
      {listItem.map((row, indexRow) => (
        <Grid container spacing={2} key={indexRow} pb={4}>
          <Grid item xs={12}>
            <div className="boxShadowEvent">
              <div
                className="homeImgItemEvent"
                // onClick={() => viewContent(row.key)}
                style={{
                  backgroundImage: `url(${row.img})`,
                }}
              ></div>
              <Typography padding={"10px"} fontWeight={"bold"}>
                {row.title}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ))}
    </CarouselDefault>
  );
}
