import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CarouselDefault } from "react-responsive-carousel";
import { Grid } from "@mui/material";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const images = [
  {
    imgPath:
      "https://hrc-prod-requests.s3-us-west-2.amazonaws.com/_1200x600_crop_center-center_none/Seventh_Day_Adventist_1600x900.jpg",
    content:
      "<p><strong>Here you will find all the past sessions by category</strong></p>",
    title: "Past Events repository",
    id: "0",
  },
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.24.56.png",
    content:
      ' <strong class="o-title"> Ryan Day, 3ABN pastoral Department  </strong>    <hr class="wp-block-separator is-style-wide" />    <p class="has-text-align-center"> <em>“discipleship, generation-Z, gen-Z, ryan day, 3ABN, pastor, post-modernist mentality, Hyveth Williams, emergent, Andrews University, Seminary</em><br /> </p>',
    title: "Operation Gen-Z", 
    id: "6545612145645",
  },
  {
    label: "Bird",
    imgPath:
      "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.16.07.png",
    content: '<strong class="o-title"> Dr. Gary Frazer, Director of Adventist Health Studies, Loma Linda University </strong>    <hr class="wp-block-separator is-style-wide" />    <p class="has-text-align-center"> <em>“A presentation of the Adventist Health Study 2, history, research, findings, and implications.</em><br /> </p>',
    title: "Adventist Health Study 2: Better Health for Everyone!",
    id: "1002",
  },
];

export default function Carousel(props) {
  const [t] = useTranslation("global");
  const { toView } = props;
  return (
    <CarouselDefault infiniteLoop showThumbs={false} autoPlay>
      {images.map((item, index) => (
        <div
          key={index}
          className="containerImgHome"
          style={{
            textAlign: "left",
            backgroundImage: `url(${item.imgPath})`,
            maxHeight: "560px",
            minHeight: "560px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            backgroundSize: "cover",
          }}
        >
          <img src={item.imgPath} style={{ display: "none" }} />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              className="containerContentVideo"
              minHeight={"470px"}
            >
              <Typography
                fontSize={32}
                style={{
                  background: "rgba(47,85,127,0.4)",
                  width: "fit-content",
                  padding: "0px 10px",
                }}
              >
                {item.title}
              </Typography>
              <div
                className="maxWidth50"
                style={{
                  overflow: "clip",
                  maxHeight: "178px",
                  marginBottom: "20px",
                  fontSize: "20px",
                }}
              >
                {parse(item.content)}
              </div>
              <div>
                {item.id === "0" ? (
                  <></>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                    style={{
                      marginRight: "10px",
                      color: "#000",
                      marginBottom: "10px",
                      background: "#fff",
                    }}
                    onClick={() => toView(item.id)}
                  >
                    {t("page.home.play")}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      ))}
    </CarouselDefault>
  );
}
