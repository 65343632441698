import * as React from "react";

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { isAuthenticated } from "../../apis/utils";

export default function Index() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const { id } = useParams();
  const [displayImg, setDisplayImg] = React.useState("flex");
  const [objContent, setObjContent] = React.useState({
    content: "",
    link: "",
    title: "...",
    organizationName: "",
    speaker: "",
    tags: "...",
    img: "",
  });
  const playContent = () => {
    setObjContent({ ...objContent, link: objContent.link + "&autoplay=1" });
    setTimeout(() => setDisplayImg("none"), 500);
  };

  React.useEffect(() => {
    if (!isAuthenticated()) {
      return navigate("/login");
    }

    let objPage = {
      content: "",
      link: "",
      title: "",
      organizationName: "...",
      tags: "...",
      speaker: "...",
      img: "",
    };

    if (id == 789) {
      objPage.content =
        "<p>A rich discussion oo protecting students online with education and technology leaders from around the world. </p>";
      objPage.link =
        "https://player.vimeo.com/video/556026594?h=5eef8fb0de&title=0&byline=0&portrait=0";
      objPage.title = "Protecting Students Online Round Table Discussion ";
      objPage.tags =
        "<p>Journal, Adventist, education, Faith-Ann, Mcgarrell, protecting, students, online roundtable, discussion, Gary Bradley Stella Bradley, Jeannie Larrabee, Leni Casimiro, Annette Melgosa, Ernest Staats</p>";
      objPage.speaker =
        "<p>Dr. Faith-ann McGarrell, Editor The Journal of Adventist Education, General Conference of Seventh-day Adventists, Dr. Gary Bradley, Professor of Education Southern Adventist University, Mrs. Stella Bradley, English teacher Collegedale Academic & Adjunct Professor, Southern Adventist University, Jeannie Larrabee, 6-8 Grade Bible and Science Teacher, Captain Gilmer Christian School, Dr. Leni Casimiro, Director/Professor Adventist International Institute of Advanced Studies (AIIAS), Dr. Annette Melgosa, Strategic Planning and Research Analyst for IT Services, General Conference of Seventh-day Adventists, Ernest Staats, Technical Data Protection Officer, General Conference of Seventh-day Adventists </p>";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/conference1.png";
    } else if (id == 123) {
      objPage.content =
        "<p>Leaning into the future and connecting to the world! Learn online teaching & learning strategies to maximize student engagement, faith integration, and eager student participation in the digital learning environment. Discover how to protect students online and learn new teaching ‘best practices’ for the online teacher. </p>";
      objPage.link =
        "https://www.youtube.com/embed/XElXINdW4wQ?si=k-2btIpJIN8c4kf0";
      objPage.title = "Babcock University Documentary 2015";
      objPage.organizationName = "Organization Name";
      objPage.tags =
        "Babcock, university, documentary, Nigeria, West Africa, Adventist, holistic, education, mind, body, spirit, diversity, different, dynamic, Onuoha Luke, Adekunle Alalade, J.A. Kayode Makinde, Olaore Israel, Ojewole Olutunde, Owolabi Shitta, Olakunbi Oyelese, Iheanyichukwu Okoro, Iyanda Olukunle, Janet Olufunmilayo Ola, Onuoha Luke";
      objPage.speaker =
        "<p>Professor Adekunle Alalade, Pioneer President/Vice Chancellor emeritus, Professor J.A. Kayode Makinde, President/Vice Chancellor, Dr. Olaore Israel, Senior Lecturer, Department of Religious Studies, Dr. Ojewole Olutunde, University Pastor, Dr. Owolabi Shitta, Alumni President/Pharmacist, Olakunbi Oyelese, Alumna/CEO April By Kunbi, Professor Iheanyichukwu Okoro, Senior Vice President/Provost College of Medicine, Mr. Iyanda Olukunle, Vice President Development & Strategy, Dr. Janet Olufunmilayo Ola, Vice President Students Development,  Dr. Onuoha Luke, Vice President Financial Administration </p>";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/conference3.png";
    } else if (id == 6545612145645) {
      objPage.content =
        "<p> A discussion of reaching Generation-Z (more postmodernist than previous generations) for Christ.</p> ";
      objPage.link =
        "https://player.vimeo.com/video/555853835?h=14f47d7ecc&title=0&byline=0&portrait=0";
      objPage.speaker = "<p> Ryan Day, 3ABN pastoral Department </p>";
      objPage.tags =
        "<p>discipleship, generation-Z, gen-Z, ryan day, 3ABN, pastor, post-modernist mentality, Hyveth Williams, emergent, Andrews University, Seminary </p>";
      objPage.title = "Operation Gen-Z";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.24.56.png";
    } else if (id == 6545612145644) {
      objPage.content =
        "<p> When God calls, in spite of yourinsecurities or doubts, God will equip you for His service </p>";
      objPage.link =
        "https://player.vimeo.com/video/554434334?h=cea8a4a241&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.tags =
        "Many, few, Robert Folkenberg, Chinese, Union, Mission, Discipleship, ";
      objPage.speaker =
        "<p>Robert Folkenberg Jr., President, Chinese Union Mission </p>";
      objPage.title = "By Many or By Few";
      objPage.img =
        "https://www.villagesdachurch.org/uploads/1/1/2/9/11299355/3-angels-messages-color_orig.png";
    } else if (id == 1) {
      objPage.content = "";
      objPage.link = "";
      objPage.organizationName = "Organization Name";
      objPage.speaker = "Speaker Name";
      objPage.title = "";
      objPage.img = "";
    } else if (id == 663258956) {
      objPage.content =
        "<p>A presentation on the value of Adventist education as evangelism resulting in spiritual development.</p>";
      objPage.link =
        "https://player.vimeo.com/video/536870913?h=0e1133f212&dnt=1&app_id=122963&html5=1&title=1&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.speaker =
        "<p>Dr. John Wesley Talyor V, President Andrews University, former Associate Director General Conference Department of Education </p>";
      objPage.title = "Does Seventh-day Adventist Education Make a Difference";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/conference2.png";
    } else if (id == 3339) {
      objPage.content =
        "<p>Adventist Christian education is central to ministerial and discipleship efforts, five days a week. </p>";
      objPage.link =
        "https://player.vimeo.com/video/554455250?h=ace1d74063&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.tags =
        "<p>church school, ministry, discipleship, collaborative, Scott Ward, Andrews University Seminary, Adventist Worldview, </p>";
      objPage.speaker =
        "<p>Dr. Scott Ward, Assistant Professor of Discipleship & Lifespan Education </p>";
      objPage.title = "Collaborative Church School Ministry";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.31.32.png";
    } else if (id == 2226) {
      objPage.content =
        "<p>Global youth pose questions to Dr. Juan Prestol -Puesan regarding financial issues and utilization of church funds for mission. </p>";
      objPage.link =
        "https://player.vimeo.com/video/558231235?h=e883b4bf20&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.speaker =
        "<p>Dr. Juan Prestol, Treasurer, General Conference of Seventh-day Adventists, Host: Kenia Reyes, Youth Department General Conference of Seventh-day Adventists  </p>";
      objPage.title =
        "Discipleship – Financial Interview with Juan Prestol-Puesan";
      objPage.tags =
        " <p>Juan Prestol, Kenia Reyes, General Conference of Seventh-day Adventists, finances, discipleship, youth, CFO, Crypto currency, block-chain technology  </p> ";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.32.09.png";
    } else if (id == 985) {
      objPage.content =
        "<p>A presentation on how to share faith with well-educated persons; approaches and strategies through a scientific lens to reach all for Christ. </p";
      objPage.link =
        "https://player.vimeo.com/video/554423905?h=a6ad5d358a&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.speaker =
        " <p> Dr. Ben Clausen, Lead Researcher, Geo Science Research Institute </p> ";
      objPage.tags =
        " <p> faith, science, Adventists, community, Ben Clausen, Geo Science Research Institute, GRI, sharing, well-educated, approaches, strategies, lens </p> ";
      objPage.title =
        "What Seventh-day Adventists have to offer the Scientific Community.";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.44.20.png";
    } else if (id == 986) {
      objPage.content =
        "<p>An introduction to EXSEED, STEM, and STREAM programs and it’s intersection with faith and learning.  </p";
      objPage.link =
        "https://player.vimeo.com/video/554442741?h=33d6f22809&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.tags =
        "<p>STEM, EXSEED, Science, Technology, Engineering, Math, Ron Carter, Marilyn Eggers, Charity Espina, DP Harris, Loma Linda University, STREAMS, religion, arts, service, spiritual, faith, mission-focused, project-based learning, PBL, critical thinking, problem-solving, active learning, teaching, hands-on </p>";
      objPage.speaker =
        "<p> Dr. Ron Carter, Provost, Co-Executive EXSEED Director, Dr. Marilyn Eggers, Associate Provost, Dr. Charity Espina, EXCEED program manager & PBL STEM Coach, & Dr. DP Harris, Vice President for Information Services, EXSEED Innovation Technology Director, Loma Linda University. </p>";
      objPage.title =
        "EXSEED Introduction: Excellence in STEM experiential education";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-01.53.26.png";
    } else if (id == 987) {
      objPage.content =
        "<p> A presentation about how to celebrate CREATION Sabbath annually.  </p";
      objPage.link =
        "https://player.vimeo.com/video/555833731?h=aa9eb5862a&title=0&byline=0&portrait=0";
      objPage.organizationName = "Organization Name";
      objPage.tags =
        "<p> faith, science, Tim Standish, Geo Science Research Institute, Creation, Sabbath, guide, creation, reflection, everyone, community, activity, team, imagination, creativity, outside, now, new </p>";
      objPage.speaker =
        "<p>Dr. Timothy Standish, Environmental Biology & Public Policy, Geo Science Research Institute </p>";
      objPage.title = "CREATION Sabbath: A How to Guide";
      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.01.06.png";
    } else if (id == 988) {
      objPage.title = "Living Wisely Beyond the Pandemic";

      objPage.link =
        "https://player.vimeo.com/video/554439245?h=c36af68b23&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Peter Landless, Director General Conference Health Ministries</p>";

      objPage.content =
        "<p> A presentation on healthy living in God’s image, beyond the COVID-19 pandemic and beyond.   </p";

      objPage.tags =
        "<p> Living, wisely, pandemic, COVID-19, Peter Landless, General Conference of Seventh-day Adventists, health, wellness, ministry, stewardship, God’s image, fresh air, exercise, water, rest, abstinence, nutrition, diet, sunshine, trust in God, stress, immunizations, obesity, age. </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.12.25.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1002) {
      objPage.title = "Adventist Health Study 2: Better Health for Everyone!";

      objPage.link =
        "https://player.vimeo.com/video/554452346?h=d9653be7c1&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Gary Frazer, Director of Adventist Health Studies, Loma Linda University  </p>";

      objPage.content =
        "<p>A presentation of the Adventist Health Study 2, history, research, findings, and implications.   </p";

      objPage.tags =
        "<p>Adventist, health, study, wellness, Gary Frazer, Loma Linda University, food choices, habits  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.16.07.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1003) {
      objPage.title = "Health: Ways to Retain and Maintain Good Health. ";

      objPage.link =
        "https://player.vimeo.com/video/554449930?h=e3967cbed8&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p> Dr. Richard Hart, President, Dr Scott Lee, Diabetes Treatment Center, Director, Dr. Edward Bitok, Chair of Nutrition & Dietetics, Chef Cory Gheen, Executive Chef,  Dr. April Wilson, Chair Department of Preventive Medicine, & Olivia Moses, Director Wholeness Institute, Loma Linda University, </p>";

      objPage.content =
        "<p> A presentation on the relationship between health and wellness, nutrition, activity, acute and chronic disease. </p";

      objPage.tags =
        "<p>Health, good, retain, maintain, Richard Hart, Scott Lee, Edward Bitok, Cory Gheen, April Wilson, Olivia Moses, wellness,   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.21.12.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1004) {
      objPage.title = "Effective Health Ministry and Vaccines: Friend or Foe? ";

      objPage.link =
        "https://player.vimeo.com/video/554437182?h=06fd234b52&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p> Dr. David Williams, Chair, Department of Social and Behavioral Sciences, Professor of African & African American Studies, Harvard University. Associate Director, Health Ministries, General Conference of Seventh-day Adventists.   </p>";

      objPage.content =
        "<p> A presentation founded on scripture, supported by prophecy, underpinning modern medicine, preventive healthcare, and more.  </p";

      objPage.tags =
        "<p> David, Williams, Harvard, University, Health, Ministries, General Conference of Seventh-day Adventists, Health, wellness, vaccines, friend, foe, Christ-centered, ministry, science, prophecy, Bible, habits, immunity, prevention   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.26.22.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1005) {
      objPage.title = "Jesus is Coming Soon! I Will Go";

      objPage.link =
        "https://player.vimeo.com/video/554495820?h=998a858d37&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Ted Wilson, President, Seventh-day Adventist Church </p>";

      objPage.content =
        "<p>Sabbath Sermon from Global Campmeeting 2021.  Jesus is Coming Soon!  </p";

      objPage.tags =
        "<p> Mission, Sabbath, Sermon, Ted Wilson, President, Seventh-day Adventist Church, God, plan, I Will Go, remnant, end time, prophecy, Jesus, coming, soon </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.30.03.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1006) {
      objPage.title = "Mission to the Cities: An Impossible Possibility ";

      objPage.link =
        "https://www.youtube.com/embed/h1wboSHTtD4?si=CCjO0KWTlprtjhz3";

      objPage.speaker =
        "<p>Dr. Mark Finley, Assistant to the President, General Conference of Seventh-day Adventists </p>";

      objPage.content =
        "<p>A presentation on how to effectively bring the message of Christ to the cities.   </p";

      objPage.tags =
        "<p> Mark Findley, General Conference, Assistant, President, effectively, Christ, mission, cities, message, mission, ministry </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.32.55.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1007) {
      objPage.title = "Life Hope Centers";

      objPage.link =
        "https://www.youtube.com/embed/94ngmHgZW4U?si=ZydqFV1RcdxVPHvt";

      objPage.speaker =
        "<p> Dr. Ivan Williams, Director – Ministerial, North American Division of Seventh-day Adventists, Doug Venn , Global Missions Urban Centers Director, General Conference of Seventh-day Adventists </p>";

      objPage.content =
        "<p> A model of ministry to reach urban populations through Life Hope Centers by meeting people’s immediate needs within the capacity of local churches.  </p";

      objPage.tags =
        "<p>Ivan, Williams, Doug, Venn, urban, Centers, Influence, Mission, best-practices, ministry, motion    </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.36.35.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1008) {
      objPage.title = "Planting a Church in the City ";

      objPage.link =
        "https://www.youtube.com/embed/7YI1JuklMcg?si=Ht0t7DU20U83JCPu";

      objPage.speaker =
        "<p> Rick Kajiura, Communication Director Adventist Mission, Pastor Roy Kim, Church Planter Melbourne City Church </p>";

      objPage.content =
        "<p> A presentation on planting a church in downtown Melbourne Australia. </p";

      objPage.tags =
        "<p> Planting, planter, church, city, Melbourne, Australia, Rick, Kajiura, Roy, Kim, Bible, worker, mission, ministry, social media, community, referrals, meetup.com, Bible-studies  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.39.38.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1009) {
      objPage.title =
        "Mission Impact Fund report, fall council 2023, General Conference of Seventh-day Adventists, Planned Giving & Trust Services and Treasury Departments ";

      objPage.link =
        "https://player.vimeo.com/video/869212380?h=55e47e78f5&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Paul Douglas, CFO Treasurer, General Conference of Seventh-day Adventists</p>";

      objPage.content =
        "<p>A report of 2022-2023 global projects implemented by local churches through the use of mission impact funds.    </p";

      objPage.tags =
        "<p> Paul, Douglas, Planned, Giving, Trust, Services, Mission, Impact, Fund, Treasury, General, Conference, local, church, projects  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.43.29.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1010) {
      objPage.title = "Biblical Hermeneutics, an Adventist Approach ";

      objPage.link =
        "https://player.vimeo.com/video/554483953?h=0e99c05959&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Frank Hasel, Associate Director Biblical Research Institute  </p>";

      objPage.content =
        "<p> A presentation on the importance of interpretation (Hermeneutics) when reading the Bible.  </p";

      objPage.tags =
        "<p>Theology, spirituality, Biblical, Hermeneutics, Adventist, Frank Hasel, BRI, Biblical Research Institute, interpretation, literal, context, agenda, meaning. </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.47.04.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1011) {
      objPage.title =
        "The Magnificent Gift: Six Indispensable Blessings of Ellen White’s Writings. ";

      objPage.link =
        "https://player.vimeo.com/video/592309762?h=2e8d95c160&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Merlin Burt, Director White Estate, Dr. Chantal Klingbeil, Associate Director White Estate, and Dwain Esmond, Associate Director White Estate,. </p>";

      objPage.content =
        "<p> A presentation of the prophetic gift given to Ellen G. White, and her writings as a light to take us to the Bible. Explore Ellen’s six gifts as explained by the White Estate: compass, GPS, telescope, microscope, filter, and lighthouse.  </p";

      objPage.tags =
        "<p> Theology, Spirituality, magnificent, gift, six indispensable, blessings, Ellen White, writings, Merlin Burt, White Estate, connect, Jesus, analogies, compass, telescope, microscope, GPS, filter, lighthouse,  Chantal Klingbell, Dwain Esmond.  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.51.12.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1012) {
      objPage.title = "Triune God in Ellen White’s Writings ";

      objPage.link =
        "https://player.vimeo.com/video/554435935?h=e0a7f842e4&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Donny Chrissutianto, Assistant Professor of Historial Studies, MDiv Program Director, Adventist International Institute of Advanced Studies (AIIAS)  </p>";

      objPage.content =
        "<p>Explore the mystery of the work of salvation and the nature of the Triune God.    </p";

      objPage.tags =
        "<p> Triune, God, Ellen White, writings, theology, spirituality, Donny Chrissutianto, AIIAS, Advntist International Institute of Advanced Studies, MDiv, Program Director,</p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.53.10.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1013) {
      objPage.title = "Who is God? ";

      objPage.link =
        "https://player.vimeo.com/video/592303339?h=c9a3753679&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Clinton Wahlen, Associate Director, Biblical Research Institute  </p>";

      objPage.content =
        "<p> An exploration of God through Biblical and prophetic reflection.  </p";

      objPage.tags =
        "<p> Who is God, Theology, spirituality, Clinton, Wahlen, Biblical Research Institute, BRI, Jesus, Son of God, biblical, prophetic, eternal, unity, one in nature  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-02.58.19.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1014) {
      objPage.title =
        "Exploring Intrinsic Religiosity as a Means to Militate Against Risk Sexual Behavior ";

      objPage.link = "https://player.vimeo.com/video/709343678?h=b29346532a";

      objPage.speaker =
        "<p>Dr. Olivia Bomester, Professor, Helderberg College of Higher Education.  </p>";

      objPage.content =
        "<p>Developing guidelines for education curricula for Christian faith-based school: exploring intrinsic, religiosity as a means to militate against adolescent risky sexual behavior.   </p";

      objPage.tags =
        "<p> intrinsic, religiosity, risk, sexual, behavior, adolescents, Christian, faith-based, school, theology, spirituality, Helderberg, college, higher, education, militate,  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.01.09.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1015) {
      objPage.title = "Drinks for Dehydrated Souls";

      objPage.link =
        "https://player.vimeo.com/video/554470321?h=600fb3a7b5&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p> Dr. Barry Black, Chaplain of the United States Senate </p>";

      objPage.content =
        "<p> A presentation on breaking down barriers and going out of your way to bless others by being kind, showing friendliness, and avoiding arguments and contention.  </p";

      objPage.tags =
        "<p> Race, unity, Barry Black, culture, drinks, dehydrated, souls, United States, Senate, breaking, barriers, bless, others, arguments, contention, friendliness, kind </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.16.47.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1016) {
      objPage.title = "Culture, Race and the Church";

      objPage.link =
        "https://player.vimeo.com/video/558251852?h=e21754827c&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Ella Simmons, General Vice President General Conference of Seventh-day Adventists & Jennifer Woods, Associate General Council, General Conference of Seventh-day Adventists, host – Jennifer Stymiest.  </p>";

      objPage.content =
        "<p> A presentation on race, the history of racism, discrimination, culture, and the global Adventist church.  In addition, next steps will be discussed, to facilitate a worldwide family of believers, all created in God’s image.  </p";

      objPage.tags =
        "<p> Culture, race, Adventist, church, Ella, Simmons, Jennifer Woods, Jennifer Stymiest, racism, discrimination, next steps, stereotypes, prejudice, ethnocentrism, history.     </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.19.45.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1017) {
      objPage.title = "Healing my Implicit Bias";

      objPage.link =
        "https://player.vimeo.com/video/554417093?h=97d6395dd8&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. David Williams, Chair, Department of Social and Behavioral Sciences, Professor of African & African American Studies, Harvard University. Associate Director, Health Ministries, General Conference of Seventh-day Adventists.   </p>";

      objPage.content =
        "<p> An exploration of implicit bias related to how our brains work, and how to intentionally heal our bias’s to better reflect the character of God. </p";

      objPage.tags =
        "<p>healing, implicit, bias, conscious, unconscious, David, Williams, Harvard, University, Health, Ministries, General Conference of Seventh-day Adventists, psychology, unintentional, subconscious, discrimination, normal, universal, racism, race, culture   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.22.50.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1018) {
      objPage.title =
        "Culturally Sensitive Leadership in a Culturally Sensitive World ";

      objPage.link =
        "https://player.vimeo.com/video/554428104?h=de52181172&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Erich Baumgartner, Director Global Leadership Institute, General Conference of Seventh-day Adventists, Professor School of Leadership, Andrews University  </p>";

      objPage.content = "<p> </p";

      objPage.tags =
        "<p> Erich Baumgartner, Andrews University, Leadership, Management, Global Leadership Institute, General Conference of Seventh-day Adventists, Culturally Sensitive, world, School of Leadership, influence, high, low, on-stage, back-stage, assertive, collective, attributes  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.24.33.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1019) {
      objPage.title =
        "Developing Christian Virtues and Character: Theological, Psychological & Practical Insights ";

      objPage.link =
        "https://player.vimeo.com/video/555833417?h=9a52907a08&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Duane Covrig, Professor of Leadership and Ethics, School of Leadership, Andrews University, Dr. Nadia Nosworthy, Associate Professor of Education Psychology, Education Psychology Program Coordinator, Dr. Stanley Patterson, Professor of Christian Ministry Department and Executive Director of the Christian Leadership Center, Andrews University.  </p>";

      objPage.content =
        "<p> Biblically founded presentation on building Christian virtues and character from several perspectives. </p";

      objPage.tags =
        "<p> Christian, Virtues, Character, Theological, Psychological, Practical, insights, Duane Covrig, Nadia Nosworthy, Stanley Patterson, Andrews University, leadership, management   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.27.00.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1020) {
      objPage.title = "7 Leadership Lessons from Moses ";

      objPage.link =
        "https://player.vimeo.com/video/554422537?h=aba43f1ff0&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Dr. Ron Clouzet, Ministerial Director of the Northern Asian Pacific Division  </p>";

      objPage.content =
        "<p>Explore the ministry of the great leader Moses: Vision </p";

      objPage.tags =
        "<p>: leadership, Ron Clouzet, Ministerial, Northern Asian Pacific Division, lessons, Moses, vision, intercession, delegation, decision, toleration, determination, submission, management   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.30.51.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1021) {
      objPage.title = "Social Media Marketing ";

      objPage.link =
        "https://player.vimeo.com/video/554446021?h=6a7970548b&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Sebastian Latorre, Creative Services Director for Outpost Centers International  </p>";

      objPage.content =
        "<p>A presentation on valuable social media marketing tools to be used for mission.  </p";

      objPage.tags =
        "<p> Brands, test of time, emotional, cultural, purpose, media, ministry, Sebastian Latorre, Creative director, Outpost Centers International, social media strategy, audience, channels, best practices, community, selling, evaluation. </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.32.37.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1022) {
      objPage.title =
        "Mission Passport: A Tool to Stay Focused on Mission by Hope Channel ";

      objPage.link =
        "https://player.vimeo.com/video/558122813?h=9b1be56e86&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Philip Mathew, Analytics and Innovation Department, Hope Channel International Inc.   </p>";

      objPage.content =
        "<p> The launch of the Mission Passport, a tool to help media ministries stay focused on Mission through research and intentional program design.  </p";

      objPage.tags =
        "<p>Mission, passport, media, ministries, tool, focused, Hope Channel, International, Philip Mathew, Adventist  </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.34.08.png";
      objPage.organizationName = "Organization Name";
    } else if (id == 1023) {
      objPage.title = "Adventist World Radio 50 Years of Ministry ";

      objPage.link =
        "https://player.vimeo.com/video/554473738?h=6ff3a346db&title=0&byline=0&portrait=0";

      objPage.speaker =
        "<p>Karen Glassford, Director Centers for Digital Evangelism AWR </p>";

      objPage.content =
        "<p> Discover how AWR innovated during the end of the pandemic to reach listeners around the world.  </p";

      objPage.tags =
        "<p>Adventist, world, radio, 50 years, ministry, AWR, Karen, Glassford, digital, evangelism   </p>";

      objPage.img =
        "https://www.electi.cl/wp-content/uploads/2023/10/Captura-de-pantalla-2023-10-19-a-las-03.36.16.png";
      objPage.organizationName = "Organization Name";
    } else {
      objPage.content =
        "Lorem ipsum, dolor si t amet consectetur adipisicing elit.<br /> Accusamus voluptatibus fugiat molestias provident vitae itaqu           <br />voluptatem numquam voluptate totam suscipit laudantium nihil cu autem ab optio, ducimus ipsam aspernatur? In.";
      objPage.link =
        "https://www.youtube.com/embed/GtZcbpFiXmA?si=MUdrNI8f97IjicUS";
      objPage.title = "Example";
      objPage.img =
        "https://png.pngtree.com/background/20230519/original/pngtree-an-old-style-dining-table-ready-for-a-family-meal-picture-image_2664840.jpg";
    }
    setObjContent(objPage);
  }, []);

  const goBack = () => {
    return navigate("/home");
  };

  return (
    <>
      <iframe
        style={{ width: "100vw", height: "100vh" }}
        src={objContent.link}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>

      {/* <iframe  src={objContent.link} style={{ width: "100vw", height: "100vh" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
       */}
      <div
        className="imgViewDetail"
        style={{
          backgroundImage: objContent.img ? `url(${objContent.img})` : "none",
          display: displayImg,
        }}
        onClick={() => playContent()}
      >
        <PlayCircleIcon color="primary" style={{ fontSize: "100px" }} />
      </div>

      <Container maxWidth="md">
        <Grid container mt={3} direction="row" justifyContent="space-between">
          <Grid item xs={9}>
            <Typography fontSize={"25px"}>{objContent.title}</Typography>
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <Button
              onClick={goBack}
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              {t("page.view.back")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            mt={4}
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            {/*  <div style={{ display: "flex", alignItems: "center" }}>
              <AccountCircleIcon
                style={{ marginRight: "10px", color: "rgb(108, 107, 107)" }}
                sx={{ width: 50, height: 50 }}
              />{" "}
              <Typography fontSize={"20px"}>
                {objContent.organizationName}
              </Typography>
            </div> */}

            {/* <Typography fontSize={"20px"}>
              <strong>Speaker: </strong> {objContent.speaker}
            </Typography> */}
          </Grid>
        </Grid>
        <Card style={{ margin: "20px 0" }}>
          <CardContent style={{ background: "#D9D9D9" }}>
            <Grid container>
              <Grid item xs={12} mt={1}>
                <Typography mb={2} fontWeight={"bold"}>
                  Speakers
                </Typography>
                {parse(objContent.speaker)}
                <Typography mb={2} fontWeight={"bold"}>
                  Description
                </Typography>
                {parse(objContent.content)}
                <Typography mb={2} fontWeight={"bold"}>
                  Tags
                </Typography>
                {parse(objContent.tags)}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
