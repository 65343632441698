import * as React from "react";
import {
  FormControl,
  MenuItem,
  Menu,
  Toolbar,
  Grid,
  Button,
  Select,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MenuHeader({}) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const [languaje, setLanguaje] = React.useState(i18n.language);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const openMenuMobile = (event) => {
    setAnchorEl(event.currentTarget);
    setMobileOpen(!mobileOpen);
  };

  const changeLanguaje = (valueLanguaje) => {
    i18n.changeLanguage(valueLanguaje);
    setLanguaje(valueLanguaje);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.removeItem("aet");
    return navigate("/login");
  };

  const goToAccount = () => {
    handleClose();
    return navigate("/account");
  };

  return (
    <Toolbar>
      <Grid
        item
        textAlign={"right"}
        width={"100%"}
        alignItems={"center"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <FormControl size="small">
          <Select
            value={languaje}
            className="paddingSelect"
            style={{
              minWidth: "130px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
            }}
            onChange={(e) => changeLanguaje(e.target.value)}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"es"}>Español</MenuItem>
          </Select>
        </FormControl>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircleIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => goToAccount()}>
            {t("component.profileMenu.myaccount")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              logOut();
            }}
          >
            {t("component.profileMenu.logout")}
          </MenuItem>
        </Menu>
      </Grid>
    </Toolbar>
  );
}
