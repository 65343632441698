import * as React from "react";
import {
  Container,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HeaderLogin from "../../components/HeaderLogin";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/advlogo.png";
import Carousel from "./Carousel";
import SearchIcon from "@mui/icons-material/Search";
import CarouselMobile from "./CarouselMobile";

export default function Index() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [tabActive, setTabActive] = React.useState(null); //9=about 1= agenda ....
  const [search, setSearch] = React.useState("");
  const [listItem, setListItem] = React.useState([]);

  const [imgHome, setImgHome] = React.useState(
    "https://www.campmeeting.com/wp-content/uploads/2021/09/Screen-Shot-2021-09-01-at-2.34.02-PM-1100x500.png"
  );
  const handleChange = (event, newValue) => {
    setTabActive(newValue);
  };

  const toView = () => {
    return navigate("/view/663258956");
  };
  const viewContent = (key) => {
    console.log(key);
    return navigate("/view/" + key);
  };

  const filterCategory = (valueSearch) => {
    setSearch(valueSearch);
  };

  React.useEffect(() => {
    console.log("change search");
    if (search == "") {
      setListItem(dataHome);
    } else {
      let filterData = dataHome.filter(
        (item) => item.title.toUpperCase().search(search.toUpperCase()) >= 0
      );
      console.log(filterData);
      setListItem(filterData);
    }
  }, [search]);

  const dataHome = [
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.campmeeting.com/wp-content/uploads/2021/08/Screen-Shot-2021-08-27-at-4.23.01-PM-1.png",
      key: "55843225698",
      title:
        "Moda y belleza: La transformación a través de los estudios virtuales",
    },
    {
      img: "https://www.villagesdachurch.org/uploads/1/1/2/9/11299355/3-angels-messages-color_orig.png",
      key: "6545612145644",
      title: "Mediafirst: el futuro de la experiencia de audio y video",
    },
    {
      img: "https://www.villagesdachurch.org/uploads/1/1/2/9/11299355/3-angels-messages-color_orig.png",
      key: "6545612145644",
      title: "Descubre el poder de la realidad virtual",
    },
  ];

  return (
    <>
      <Container>
        <div
          style={{
            minHeight: "55px",
            marginTop: "6px",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            arginBottom: "23px",
            padding: "0 15px",
            marginBottom: "15px",
          }}
        >
          <img
            src={logo}
            alt="logo"
            width="55"
            height="55"
            className="imgIcon"
          />
          <div>
            <HeaderLogin />
          </div>
        </div>
        <Typography fontWeight={"bold"} fontSize={28} mb={3}>
          Proximos Eventos
        </Typography>
        <Carousel toView={viewContent} listItem={listItem} />

        <CarouselMobile toView={viewContent} listItem={listItem} />

        <Grid mt={3} container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={"bold"} fontSize={28} mb={3}>
              Eventos Pasados
            </Typography>
          </Grid>
          {listItem.map((row, indexRow) => (
            <Grid item xs={12} sm={6} md={4} key={indexRow}>
              <div className="boxShadowEvent">
                <div
                  className="homeImgItemEvent"
                  onClick={() => viewContent(row.key)}
                  style={{
                    backgroundImage: `url(${row.img})`,
                  }}
                ></div>
                <Typography padding={"10px"} fontWeight={"bold"}>
                  {row.title}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
