import * as React from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export default function HeaderLogin(props) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const [languaje, setLanguaje] = React.useState(i18n.language);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuConfig, setmenuConfig] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenuMobile = (event) => {
    setAnchorEl(event.currentTarget);
    setMobileOpen(!mobileOpen);
  };

  const changeLanguaje = (valueLanguaje) => {
    i18n.changeLanguage(valueLanguaje);
    setLanguaje(valueLanguaje);
  };

  const login = () => {
    return navigate("/login");
  };
  const register = () => {
    return navigate("/createAccount");
  };

  const optionConfigMenu = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      p={1}
    >
      <FormControl size="small">
        <Select
          value={languaje}
          className="paddingSelect"
          style={{
            minWidth: "130px",
            textAlign: "left",
            color: "#000",
            background: "#fff",
          }}
          onChange={(e) => changeLanguaje(e.target.value)}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Español</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="text"
        color="secondary"
        style={{
          marginLeft: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          color: "#000",
          minWidth: "120px",
          textTransform: "none",
        }}
        onClick={login}
      >
        {t("component.header.login")}
      </Button>
      <Button
        variant="outlined"
        style={{
          marginLeft: "10px",
          color: "#000",
          minWidth: "120px",
          borderColor: "#000",
          textTransform: "none",
        }}
        onClick={register}
      >
        {t("component.header.register")}
      </Button>
    </Grid>
  );

  return (
    <>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={openMenuMobile}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={mobileOpen} onClose={openMenuMobile}>
        {optionConfigMenu}
      </Menu>
      <Grid sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
        <FormControl size="small">
          <Select
            value={languaje}
            className="paddingSelect"
            style={{
              minWidth: "130px",
              textAlign: "left",
              color: "#000",
              background: "#fff",
            }}
            onChange={(e) => changeLanguaje(e.target.value)}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"es"}>Español</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="text"
          color="secondary"
          style={{
            marginLeft: "10px",
            color: "#000",
            minWidth: "120px",
            textTransform: "none",
          }}
          onClick={login}
        >
          {t("component.header.login")}
        </Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: "10px",
            color: "#000",
            minWidth: "120px",
            borderColor: "#000",
            textTransform: "none",
          }}
          onClick={register}
        >
          {t("component.header.register")}
        </Button>
      </Grid>
    </>
  );
}
