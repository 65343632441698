import * as React from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/advlogo.png";
import AlertError from "./../../components/AlertError";
import { validateEmail } from "../../assets/library/GlobalFunction";
import { signin, signup } from "../../apis/Services/Authentication";
import ModalCustom from "../../components/ModalCustom";

export default function CreateAccount() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);
  const [name, setName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [passRepeat, setPassRepeat] = React.useState("");
  const [checkAccept, setCheckAccept] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successApi, setSuccessApi] = React.useState(false);
  const [error, setError] = React.useState({
    open: false,
    name: false,
    lastName: false,
    email: false,
    phone: false,
    pass: false,
    check: false,
  });
  const handleClickShowPasswordRepeat = () =>
    setShowPasswordRepeat((show) => !show);
  const handleMouseDownPasswordRepeat = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = () => {
    let _error = {
      name: false,
      open: false,
      lastName: false,
      email: false,
      phone: false,
      pass: false,
      check: false,
    };
    if (!name) {
      _error.name = true;
    }
    if (!lastName) {
      _error.lastName = true;
    }
    if (!validateEmail(email)) {
      _error.email = true;
    }
    if (!phone) {
      _error.phone = true;
    }
    if (pass !== passRepeat || pass === "") {
      _error.pass = true;
    }
    if (!checkAccept) {
      _error.check = true;
    }

    if (
      _error.name ||
      _error.lastName ||
      _error.pass ||
      _error.email ||
      _error.check ||
      _error.phone
    ) {
      _error.open = true;
      setError(_error);
      return;
    } else {
      setError(_error);
    }

    let json = {
      name: name + " " + lastName,
      email: email,
      phone: phone,
      password: pass,
    };

    setLoading(true);
    setOpen(true);

    signup(json)
      .then((result) => {
        console.log(result);
        if (result.access_token) {
          console.log("true");
          setSuccessApi(true);
          setLoading(false);
          return;
        } else if (result?.response?.statusText === "Bad Request") {
          console.log(result.response.statusText);
          setSuccessApi(false);
          setLoading(false);
        }

        setLoading(false);
      })
      .catch((x) => {
        setSuccessApi(false);
        setLoading(false);
      });
  };
  const closeModal = (isOpen, redirect = false) => {
    setOpen(isOpen);
    if (setSuccessApi) {
      return navigate("/login");
    }
  };

  return (
    <>
      <ModalCustom
        open={open}
        setOpen={closeModal}
        loading={loading}
        success={successApi}
        error={!successApi}
      />
      <Grid
        container
        minHeight={"102vh"}
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={8}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <img
              src={logo}
              alt="logo"
              width="160"
              height="160"
              className="imgIcon"
            />
          </Grid>
          <Grid item xs={12} marginBottom={3} textAlign={"center"}>
            <Typography variant="h1" fontSize={"30px"}>
              {t("page.create.register")}
            </Typography>
            <Typography> {t("page.create.message")}</Typography>
          </Grid>
          <Grid item xs={10} md={8} margin={"auto"}>
            <form className="">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label className="text-left">{t("page.create.name")}</label>
                  <TextField
                    name="name"
                    variant="outlined"
                    value={name}
                    error={error.name}
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-left">
                    {t("page.create.lastname")}
                  </label>
                  <TextField
                    name="lastName"
                    variant="outlined"
                    value={lastName}
                    error={error.lastName}
                    fullWidth
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-left"> {t("page.create.email")}</label>
                  <TextField
                    name="email"
                    variant="outlined"
                    error={error.email}
                    value={email}
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-left"> {t("page.create.phone")}</label>
                  <TextField
                    name="phone"
                    variant="outlined"
                    error={error.phone}
                    value={phone}
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-left">
                    {t("page.create.password")}
                  </label>

                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel htmlFor="outlined-adornment-password">
                 Password
               </InputLabel> */}
                    <OutlinedInput
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                      error={error.pass}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="mostrar/ocultar contraseña"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="start"
                          >
                            {showPassword ? <LockOpenIcon /> : <LockIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      //label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className="text-left">
                    {t("page.create.repassword")}
                  </label>

                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel htmlFor="outlined-adornment-password">
                 Password
               </InputLabel> */}
                    <OutlinedInput
                      onChange={(e) => setPassRepeat(e.target.value)}
                      name="passwordRpeteat"
                      value={passRepeat}
                      error={error.pass}
                      type={showPasswordRepeat ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="mostrar/ocultar contraseña"
                            onClick={handleClickShowPasswordRepeat}
                            onMouseDown={handleMouseDownPasswordRepeat}
                            edge="start"
                          >
                            {showPasswordRepeat ? (
                              <LockOpenIcon />
                            ) : (
                              <LockIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      //label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    value={checkAccept}
                    onChange={() => setCheckAccept(!checkAccept)}
                    control={<Checkbox />}
                    label={
                      <Typography color={error.check ? "error" : "initial"}>
                        {t("page.create.accepted")}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} marginBottom={"20px"}>
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                    type="submit"
                  >
                    {t("page.create.register")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Grid
          item
          xs={0}
          display={{ sm: "none", md: "block" }}
          md={4}
          style={{ padding: 0 }}
        >
          <div className="contenedorLoginImg"></div>
        </Grid>
      </Grid>
      <AlertError error={error} t={t} setError={setError} />
    </>
  );
}
